import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './App.css';
import {poster_desktop, poster_mobile} from 'assets';

const App = () => {
  return (
    <Container fluid className="fullscreen-poster text-center w-100">
      <div className="coming-soon-text text-center">COMING SOON</div>
    </Container>
  );
};

export default App;
